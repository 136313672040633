import CryptoJS from 'crypto-js'
import { convertText } from '../utils/validateFunctions'
import { Amplify } from './amplify'
import { initialAuthContextState } from './objects'
const amplify = new Amplify()

export const filterCategories = (data) => {
  const filteredData = data.map((obj) => ({
    id: obj.categoryId,
    name: obj.categoryName,
    icon: obj.categoryIcon
  }))
  const uniqueData = filteredData.filter(
    (obj, index, self) => index === self.findIndex((item) => item.id === obj.id)
  )
  return uniqueData
}

export const getSubcategories = (data) => {
  const filteredData = data.map((obj) => ({
    id: obj.subcategoryId,
    name: obj.subcategoryName,
    icon: obj.subcategoryIcon,
    categoyId: obj.categoryId,
    categoryName: obj.categoryName,
    categoryIcon: obj.categoryIcon
  }))
  const uniqueData = filteredData.filter(
    (obj, index, self) => index === self.findIndex((item) => item.id === obj.id)
  )
  return uniqueData
}

export const passwordEncrypt = (password) => {
  const hash = CryptoJS.AES.encrypt(password, process.env.REACT_APP_SK).toString()
  return hash
}

export const searchItem = (
  e,
  item,
  setItem,
  list,
  setListFiltered
) => {
  setItem({ ...item, [e.target.name]: e.target.value })
  setListFiltered(
    list.filter((listItem) => convertText(
      'elementName' in listItem ? listItem.elementName : listItem.name
    ).includes(convertText(e.target.value)))
  )
}

export const handleRequestButton = (setIsRequesting, timeOutId) => {
  clearTimeout(timeOutId)
  setIsRequesting(true)

  const id = setTimeout(() => {
    setIsRequesting(false)
  }, 10000)
  return id
}

export const activateRequestButton = (setIsRequesting, timeOutId) => {
  clearTimeout(timeOutId)
  setIsRequesting(false)
}

export const removeData = ({ navigate, setSessionData, handleShowInactiveSessionModal }) => {
  if (handleShowInactiveSessionModal) handleShowInactiveSessionModal()

  setTimeout(() => {
    if (setSessionData) {
      setSessionData(initialAuthContextState)
    }
    amplify.cleanToken()
    amplify.signOut()
    navigate('/')
  }, 500)
}

export const disableForwardAndBackwardNavigation = () => {
  window.history.pushState(null, null, window.location.href)
  window.onpopstate = function () {
    window.history.pushState(null, null, window.location.href)
  }
}

export const validateText = (text) => {
  if (text.startsWith(' ')) return false
  if (/\s{2,}/.test(text)) return false
  if (/[^a-zA-ZáéíóúÁÉÍÓÚñÑ\s]/.test(text)) return false
  if (text.length < 10) return false

  return true
}

export const handleCode = (e, index, code, setCode) => {
  const inputValue = e.target.value
  if (/^[0-9]*$/.test(inputValue)) {
    const newCode = [...code]
    newCode[index] = inputValue
    setCode(newCode.join(''))

    // Move focus
    if (inputValue.length > 0 && index < 5) {
      e.target.nextElementSibling?.focus()
    } else if (inputValue.length === 0 && index > 0) {
      e.target.previousElementSibling?.focus()
    }
  }
}

export const handlePaste = (e, index, code, setCode) => {
  e.preventDefault()
  const pasteData = e.clipboardData.getData('text')
  const newCode = [...code]
  for (let i = 0; i < pasteData.length; i++) {
    if (i + index < 6) {
      newCode[i + index] = pasteData[i]
    }
  }
  setCode(newCode.join(''))
  if (pasteData.length > 0 && index + pasteData.length < 6) {
    e.target.nextElementSibling?.focus()
  } else if (pasteData.length > 0 && index + pasteData.length === 6) {
    e.target.previousElementSibling?.focus()
  }
}

export const textWithoutEspecials = (text) => {
  const accentuated = {
    á: 'a',
    é: 'e',
    í: 'i',
    ó: 'o',
    ú: 'u',
    Á: 'A',
    É: 'E',
    Í: 'I',
    Ó: 'O',
    Ú: 'U',
    ñ: 'n',
    Ñ: 'N',
    ü: 'u',
    Ü: 'U'
  }

  text = text.replace(/[áéíóúÁÉÍÓÚñÑüÜ]/g, match => accentuated[match])
  text = text.replace(/\s+/g, '_')
  text = text.replace(/[^a-zA-Z0-9_.]/g, '')

  return text
}
