import { handleRequestButton } from '../../utils/functions'
import { resendCodeOnPendingPurchase } from '../../utils/userqueries'

export const onAcceptPendingProcessClick = async (
  navigate,
  shoppingHistoryId,
  sessionData,
  setSessionData,
  handleShowInactiveSessionModal,
  isRequesting,
  setIsRequesting
) => {
  if (isRequesting) return

  const { authTk } = sessionData

  handleRequestButton(setIsRequesting)
  const resendCodeResponse = await resendCodeOnPendingPurchase(shoppingHistoryId, authTk, navigate, setSessionData, handleShowInactiveSessionModal)
  if (resendCodeResponse?.response) {
    navigate('../BaseDeDatos/CodigoLoopay', { state: { shoppingHistoryId } })
  }
}
