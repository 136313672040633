import React, { useContext, useRef, useState } from 'react'

// Components
import { BuyDBResume } from '../../components/BuyDBResume/BuyDBResume'

// Context
import { DataContext } from '../../../../context/DataContext'

// Utils
import {
  buyDBFinalResumeBuyerString,
  buyDBUploadReceiptBuyerString
} from '../../../../utils/strings'

import './BuyDBUploadReceipt.css'
import { useNavigate } from 'react-router-dom'
import { acceptBtnModal } from './BuyDBUploadReceptFunctions'
import { AuthContext } from '../../../../context/AuthContext'
import LoopayWhiteLogo from '../../../../assets/images/Loopay-logo-blanco.png'
import WompiWhiteLogo from '../../../../assets/images/Wompi-logo-blanco.png'
import { Modal } from '../../../../components/Modal/Modal'
import { useModal } from '../../../../hooks/useModal'

export const BuyDBUploadReceipt = () => {
  const navigate = useNavigate()
  const { contextInfo } = useContext(DataContext)
  const [showModal, handleShow, handleClose] = useModal()
  const [showModal2, handleShow2] = useModal()
  const [modalMessageChecked, setModalMessageChecked] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState({
    loopay: false,
    wompi: false
  })
  const inputCheck = useRef(false)

  const { DBPrice, numberOfUsersToBuy } = contextInfo
  const totalPricePerDB = DBPrice * numberOfUsersToBuy
  const authContext = useContext(AuthContext)
  const dataContext = useContext(DataContext)

  const [isRequesting, setIsRequesting] = useState(false)

  const handleInputCheck = () => {
    setModalMessageChecked(inputCheck.current.checked)
  }

  return (
    <section className="buyDBUploadReceipt">
      <BuyDBResume planType={contextInfo.planType} />
      <div className="buyDBUploadReceiptConfirmation">
        <header className="buyDBUploadReceiptConfirmationHeader">
          <h2 className="buyDBUploadReceiptTitle">
            {buyDBUploadReceiptBuyerString.dataForPayment}
          </h2>
          <h2 className="buyDBUploadReceiptTitle">
            {buyDBUploadReceiptBuyerString.price}
          </h2>
        </header>
        <div className="buyDBUploadReceiptConfirmationContent">
          <div className="buyDBUploadReceiptConfirmationDataContent">
            <p className="buyDBUploadReceiptConfirmationParagraph">
              {`${buyDBUploadReceiptBuyerString.paymentMethod}`}
              <br />
              <span>{buyDBUploadReceiptBuyerString.usersAmount}</span>
              {contextInfo.numberOfUsersToBuy}
            </p>
          </div>
          <div className="buyDBFinalResumeConfirmationPriceContent">
            <p className="buyDBUploadReceiptConfirmationParagraph">
              {buyDBUploadReceiptBuyerString.symbol}
              {`${contextInfo.DBPrice * contextInfo.numberOfUsersToBuy * buyDBFinalResumeBuyerString.iva}`}
            </p>
          </div>
        </div>
      </div>
      <div className="buyDBUploadReceiptPayButtons">
        <button
          disabled={isRequesting}
          className="paymentDescriptionSend-btn"
          onClick={() => {
            handleShow()
            setPaymentMethod({ wompi: true, loopay: false })
          }}
        >
          {buyDBUploadReceiptBuyerString.goToLoopay}
          <img src={WompiWhiteLogo} alt="wompi-logo" />
        </button>
        <button
          disabled={isRequesting}
          className="paymentDescriptionSend-btn"
          onClick={() => {
            handleShow()
            setPaymentMethod({ wompi: false, loopay: true })
          }}
        >
          {buyDBUploadReceiptBuyerString.goToLoopay}
          <img src={LoopayWhiteLogo} alt="loopay-logo" />
        </button>
      </div>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <div className="buyDBFinalResumeModalContent">
            <input
              className="buyDBFinalResumeModalContentInput"
              type="checkbox"
              ref={inputCheck}
              onClick={handleInputCheck}
            />
            <p className="buyDBFinalResumeModalContentText">
              {buyDBFinalResumeBuyerString.haveReadAndAccept}
              <span className="buyDBFinalResumeModalContentSpan">
                {buyDBFinalResumeBuyerString.termsAndConditions}
              </span>
            </p>
          </div>
        </Modal.Content>
        <Modal.Buttons>
          <button onClick={handleClose} className="Modal-normalBtn">
            {buyDBFinalResumeBuyerString.cancel}
          </button>
          <button
            onClick={() =>
              acceptBtnModal(
                modalMessageChecked,
                navigate,
                authContext,
                dataContext,
                isRequesting,
                setIsRequesting,
                totalPricePerDB,
                handleShow2,
                paymentMethod
              )
            }
            className={`${modalMessageChecked ? 'Modal-orangeBtn' : 'Modal-disabledBtn'
              }`}
            disabled={isRequesting}
          >
            {buyDBFinalResumeBuyerString.accept}
          </button>
        </Modal.Buttons>
      </Modal>
      <Modal isOpen={showModal2}>
        <Modal.Content>
          <div className="buyDBFinalResumeModalContent">
            <p className="buyDBFinalResumeModalContentText">
              {buyDBFinalResumeBuyerString.error}
            </p>
          </div>
        </Modal.Content>
        <Modal.Buttons>
          <button
            onClick={() => {
              navigate('../Cuenta')
            }}
            className="Modal-normalBtn"
          >
            {buyDBFinalResumeBuyerString.cancel}
          </button>
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
