import { queriesBuyerString, userquerisBuyerString } from './strings'
import { Amplify } from './amplify'
import { handleSessionFailures } from './queries'
const amplify = new Amplify()

export const validateOriginalPassword = async (email, password) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/auth/buyers/validate`,
      {
        method: 'POST',
        body: JSON.stringify({
          email,
          password
        }),
        headers: {
          'Content-Type': 'application/json',
          TokenCatalogo: token
        }
      }
    )
    const json = await response.json()
    return json
  } catch (error) {
    const errorObject = { response: false, data: error }
    return JSON.stringify(errorObject)
  }
}

export const changePassword = async (email, newPassword, authContext) => {
  if (!email || !newPassword) {
    throw new Error(userquerisBuyerString.valueNull)
  } else if (typeof email !== 'string' || typeof newPassword !== 'string') {
    throw new Error(userquerisBuyerString.valuesNotString)
  }
  const {
    sessionData,
    setSessionData,
    navigate,
    handleShowInactiveSessionModal
  } = authContext
  const { authTk } = sessionData
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/buyerCredential/${email}`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          buyerCredentialPass: newPassword
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authTk}`,
          TokenCatalogo: token
        }
      }
    )
    const data = await handleSessionFailures(
      response,
      navigate,
      setSessionData,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    const errorObject = { response: false, data: error }
    return JSON.stringify(errorObject)
  }
}

export const buyerLogin = async (emails, passwords) => {
  if (!emails || !passwords) {
    throw new Error(userquerisBuyerString.valueNull)
  } else if (typeof emails !== 'string' || typeof passwords !== 'string') {
    throw new Error(userquerisBuyerString.valuesNotString)
  }
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/auth/buyer/login`,
      {
        method: 'POST',
        body: JSON.stringify({
          email: emails,
          password: passwords
        }),
        headers: {
          'Content-Type': 'application/json',
          TokenCatalogo: token
        }
      }
    )
    const json = await response.json()
    return json
  } catch (error) {
    const errorObject = { response: false, data: error }
    return JSON.stringify(errorObject)
  }
}

export const buyerRequestPasswordRecovery = async (email) => {
  if (!email) {
    throw new Error(userquerisBuyerString.valueNull)
  } else if (typeof email !== 'string') {
    throw new Error(userquerisBuyerString.notValueString)
  }
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/auth/buyer/request-reset-password`,
      {
        method: 'POST',
        body: JSON.stringify({
          email
        }),
        headers: {
          'Content-Type': 'application/json',
          TokenCatalogo: token
        }
      }
    )
    const json = await response.json()
    return json
  } catch (error) {
    const errorObject = { response: false, data: error }
    return JSON.stringify(errorObject)
  }
}

export const buyerPasswordRecoveryCodeVerify = async (email, code) => {
  if (!email || !code) {
    throw new Error(userquerisBuyerString.valueNull)
  } else if (typeof email !== 'string' || typeof code !== 'string') {
    throw new Error(userquerisBuyerString.incorrectFormat)
  }
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/auth/verifyCodeBuyer`,
      {
        method: 'POST',
        body: JSON.stringify({
          email,
          code
        }),
        headers: {
          'Content-Type': 'application/json',
          TokenCatalogo: token
        }
      }
    )
    const json = await response.json()
    return json
  } catch (error) {
    const errorObject = { response: false, data: error }
    return JSON.stringify(errorObject)
  }
}

export const buyerSetNewPassword = async (email, password) => {
  if (!email || !password) {
    throw new Error(userquerisBuyerString.valueNull)
  } else if (typeof email !== 'string' || typeof password !== 'string') {
    throw new Error(userquerisBuyerString.valuesNotString)
  }
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/auth/buyer/reset-password`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          email,
          password
        }),
        headers: {
          'Content-Type': 'application/json',
          TokenCatalogo: token
        }
      }
    )
    const json = await response.json()
    return json
  } catch (error) {
    const errorObject = { response: false, data: error }
    return JSON.stringify(errorObject)
  }
}

export const validateNumberOfUsersWhoWishToContactThem = async (
  databaseType,
  authContext,
  personalize = null
) => {
  const {
    sessionData,
    setSessionData,
    navigate,
    handleShowInactiveSessionModal
  } = authContext
  const { buyerId, companyId, authTk } = sessionData
  if (!databaseType || !companyId) {
    throw new Error(userquerisBuyerString.databaseOrIDNull)
  } else if (typeof databaseType !== 'number') {
    throw new Error(userquerisBuyerString.databaseIsNotNumber)
  } else if (typeof companyId !== 'string') {
    throw new Error(userquerisBuyerString.radicateNumIsNotString)
  }
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/shoppingHistory/db-count`,
      {
        method: 'POST',
        body: JSON.stringify({
          companyId,
          buyerId,
          databaseType,
          personalize
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authTk}`,
          TokenCatalogo: token
        }
      }
    )
    const data = await handleSessionFailures(
      response,
      navigate,
      setSessionData,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    throw new Error(`${userquerisBuyerString.error} ${error}`)
  }
}

export const validateNumberOfUsersBuyed = async (authContext) => {
  const {
    sessionData,
    setSessionData,
    navigate,
    handleShowInactiveSessionModal
  } = authContext
  const { authTk, buyerId, currentUsersBuyed, ...restSessionData } = sessionData
  if (currentUsersBuyed > 0) {
    return currentUsersBuyed
  }
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/shoppingHistory/alreadyPurchased`,
      {
        method: 'POST',
        body: JSON.stringify({
          buyerId
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authTk}`,
          TokenCatalogo: token
        }
      }
    )
    const data = await handleSessionFailures(
      response,
      navigate,
      setSessionData,
      handleShowInactiveSessionModal
    )
    const updatedSessionData = {
      ...restSessionData,
      elements: data.body.amount,
      authTk,
      buyerId
    }
    setSessionData(updatedSessionData)
    return data
  } catch (error) {
    throw new Error(`${userquerisBuyerString.error} ${error}`)
  }
}

export const validateNumberOfSelectionsbyCity = async (authContext, city) => {
  const {
    sessionData,
    setSessionData,
    navigate,
    handleShowInactiveSessionModal
  } = authContext
  const { authTk, companyId, buyerId } = sessionData
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/shoppingHistory/city-count`,
      {
        method: 'POST',
        body: JSON.stringify({
          city,
          companyId,
          buyerId
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authTk}`,
          TokenCatalogo: token
        }
      }
    )
    const data = await handleSessionFailures(
      response,
      navigate,
      setSessionData,
      handleShowInactiveSessionModal
    )
    return data.body
  } catch (error) {
    throw new Error(`${userquerisBuyerString.error} ${error}`)
  }
}

export const validateNumberOfSelectionsbyGender = async (authContext, city, gender) => {
  const {
    sessionData,
    setSessionData,
    navigate,
    handleShowInactiveSessionModal
  } = authContext
  const { authTk, companyId, buyerId } = sessionData
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/shoppingHistory/gender-count`,
      {
        method: 'POST',
        body: JSON.stringify({
          city,
          gender,
          companyId,
          buyerId
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authTk}`,
          TokenCatalogo: token
        }
      }
    )
    const data = await handleSessionFailures(
      response,
      navigate,
      setSessionData,
      handleShowInactiveSessionModal
    )
    return data.body
  } catch (error) {
    throw new Error(`${userquerisBuyerString.error} ${error}`)
  }
}

export const validateNumberOfSelectionsbyAge = async (authContext, city, gender, age) => {
  const {
    sessionData,
    setSessionData,
    navigate,
    handleShowInactiveSessionModal
  } = authContext
  const { authTk, companyId, buyerId } = sessionData
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/shoppingHistory/age-count`,
      {
        method: 'POST',
        body: JSON.stringify({
          city,
          gender,
          age,
          companyId,
          buyerId
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authTk}`,
          TokenCatalogo: token
        }
      }
    )
    const data = await handleSessionFailures(
      response,
      navigate,
      setSessionData,
      handleShowInactiveSessionModal
    )
    return data.body
  } catch (error) {
    throw new Error(`${userquerisBuyerString.error} ${error}`)
  }
}

export const validateNumberOfSelectionsbySubCategory = async (authContext, searchParams) => {
  const {
    sessionData,
    setSessionData,
    navigate,
    handleShowInactiveSessionModal
  } = authContext
  const { authTk, companyId, buyerId } = sessionData
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/shoppingHistory/subCategory-count`,
      {
        method: 'POST',
        body: JSON.stringify({
          searchParams,
          companyId,
          buyerId
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authTk}`,
          TokenCatalogo: token
        }
      }
    )
    const data = await handleSessionFailures(
      response,
      navigate,
      setSessionData,
      handleShowInactiveSessionModal
    )
    return data.body
  } catch (error) {
    throw new Error(`${userquerisBuyerString.error} ${error}`)
  }
}

export const validateNumberOfSelectionsbyCategory = async (authContext, searchParams) => {
  const {
    sessionData,
    setSessionData,
    navigate,
    handleShowInactiveSessionModal
  } = authContext
  const { authTk, companyId, buyerId } = sessionData
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/shoppingHistory/category-count`,
      {
        method: 'POST',
        body: JSON.stringify({
          searchParams,
          companyId,
          buyerId
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authTk}`,
          TokenCatalogo: token
        }
      }
    )
    const data = await handleSessionFailures(
      response,
      navigate,
      setSessionData,
      handleShowInactiveSessionModal
    )
    return data.body
  } catch (error) {
    throw new Error(`${userquerisBuyerString.error} ${error}`)
  }
}

export const getDemandCode = async (sessionInfo, authContext, requestData) => {
  if (!sessionInfo || !authContext) {
    throw new Error(userquerisBuyerString.valueNull)
  } else if (typeof sessionInfo !== 'object' || typeof authContext !== 'object') {
    throw new Error(userquerisBuyerString.valuesNotString)
  }
  const { sessionData, setSessionData, navigate, handleShowInactiveSessionModal } = authContext
  const { authTk, companyComertialName, buyerCredentialEmail } = sessionData

  const { findBuyer } = sessionInfo
  const { buyer } = findBuyer
  const nit = buyer.buyerRUTPath.split('/')[1]
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/shoppingHistory/temporal-first-step`,
      {
        method: 'POST',
        body: JSON.stringify({
          nit,
          companyName: companyComertialName,
          email: buyerCredentialEmail,
          buyerId: buyer.buyerId,
          requestData
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authTk}`,
          TokenCatalogo: token
        }
      }
    )
    return await handleSessionFailures(response, navigate, setSessionData, handleShowInactiveSessionModal)
  } catch (error) {
    console.error(error)
    const errorObject = { response: false, data: error }
    return JSON.stringify(errorObject)
  }
}

export const getDemandCodeWompi = async (sessionInfo, authContext, requestData) => {
  if (!sessionInfo || !authContext) {
    throw new Error(userquerisBuyerString.valueNull)
  } else if (typeof sessionInfo !== 'object' || typeof authContext !== 'object') {
    throw new Error(userquerisBuyerString.valuesNotString)
  }
  const { sessionData, setSessionData, navigate, handleShowInactiveSessionModal } = authContext
  const { authTk, companyComertialName, buyerCredentialEmail } = sessionData

  const { findBuyer } = sessionInfo
  const { buyer } = findBuyer
  const nit = buyer.buyerRUTPath.split('/')[1]
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/shoppingHistory/temporal-first-step-wompi`,
      {
        method: 'POST',
        body: JSON.stringify({
          nit,
          companyName: companyComertialName,
          email: buyerCredentialEmail,
          buyerId: buyer.buyerId,
          requestData
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authTk}`,
          TokenCatalogo: token
        }
      }
    )
    return await handleSessionFailures(response, navigate, setSessionData, handleShowInactiveSessionModal)
  } catch (error) {
    console.error(error)
    const errorObject = { response: false, data: error }
    return JSON.stringify(errorObject)
  }
}

export const buyDataBase = async (data, authTk, navigate, setSessionData, handleShowInactiveSessionModal) => {
  if (!data || !authTk) {
    throw new Error(userquerisBuyerString.valueNull)
  } else if (typeof data !== 'object' || typeof authTk !== 'string') {
    throw new Error(userquerisBuyerString.incorrectFormat)
  }
  const body = JSON.stringify(data)
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(`${process.env.REACT_APP_HOST}/shoppingHistory/db-select`,
      {
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authTk}`,
          TokenCatalogo: token
        }
      })
    return await handleSessionFailures(response, navigate, setSessionData, handleShowInactiveSessionModal)
  } catch (error) {
    console.error(error)
  }
}

export const resendCodeOnPendingPurchase = async (shoppingHistoryId, authTk, navigate, setSessionData, handleShowInactiveSessionModal) => {
  if (!shoppingHistoryId || !authTk) {
    throw new Error(userquerisBuyerString.valueNull)
  } else if (typeof shoppingHistoryId !== 'string' || typeof authTk !== 'string') {
    throw new Error(userquerisBuyerString.incorrectFormat)
  }
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(`${process.env.REACT_APP_HOST}/shoppingHistory/buyer-first-step/${shoppingHistoryId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authTk}`,
          TokenCatalogo: token
        }
      })

    const data = await handleSessionFailures(
      response,
      navigate,
      setSessionData,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

/* export const chooseNumberOfUsersToBuy = async (
  tokens,
  companyId,
  buyerId,
  databaseType,
  count,
  personalize = null
) => {
  if (!databaseType || !companyId) {
    throw new Error(userquerisBuyerString.databaseOrIDNull)
  } else if (typeof databaseType !== 'number' || typeof count !== 'number') {
    throw new Error(userquerisBuyerString.databaseTypeIsNotNumber)
  } else if (typeof companyId !== 'string') {
    throw new Error(userquerisBuyerString.companyIdIsNotString)
  } else if (typeof count !== 'number') {
    throw new Error(userquerisBuyerString.countIsNotNumber)
  }
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const data = await fetch(
      `${process.env.REACT_APP_HOST}/shoppingHistory/db-select`,
      {
        method: 'POST',
        body: JSON.stringify({
          companyId,
          buyerId,
          databaseType,
          count,
          personalize
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokens.idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const response = await data.json()
    return response
  } catch (error) {
    throw new Error(`${userquerisBuyerString.error} ${error}`)
  }
} */

export const getDBPrice = async (databaseId, amount, authContext) => {
  if (!databaseId || !amount) {
    throw new Error(userquerisBuyerString.databaseIdOrAmountNull)
  } else if (typeof databaseId !== 'number' || typeof amount !== 'number') {
    throw new Error(userquerisBuyerString.databaseIdNotNumber)
  }
  const {
    sessionData,
    setSessionData,
    navigate,
    handleShowInactiveSessionModal
  } = authContext
  const { authTk } = sessionData
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/bd-prices/getPrice`,
      {
        method: 'POST',
        body: JSON.stringify({
          databaseId,
          amount
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authTk}`,
          TokenCatalogo: token
        }
      }
    )

    const data = await handleSessionFailures(
      response,
      navigate,
      setSessionData,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    throw new Error(`${userquerisBuyerString.error} ${error}`)
  }
}

export const updateBuyerActive = async (authContext) => {
  const { sessionData, setSessionData, navigate, handleShowInactiveSessionModal } = authContext
  const { buyerId, authTk } = sessionData
  if (!buyerId) {
    throw new Error(userquerisBuyerString.buyerIdNull)
  } else if (typeof buyerId !== 'string') {
    throw new Error(userquerisBuyerString.buyerIdNotString)
  }
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/buyer/${buyerId}`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          buyerActive: false
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authTk}`,
          TokenCatalogo: token
        }
      }
    )
    const json = await handleSessionFailures(
      response,
      navigate,
      setSessionData,
      handleShowInactiveSessionModal
    )
    return json
  } catch (error) {
    console.error(error)
  }
}

export const getStates = async () => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/state`, {
        headers: {
          TokenCatalogo: token
        }
      }
    )
    const data = await response.json()
    const dataList = {
      states: data.body
    }
    return dataList
  } catch (error) {
    console.error(error)
  }
}

export const getCities = async () => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/city`, {
        headers: {
          TokenCatalogo: token
        }
      }
    )
    const data = await response.json()
    const dataList = {
      cities: data.body
    }
    return dataList
  } catch (error) {
    console.error(error)
  }
}

export const getPqrsByRadicateNum = async (
  radicatedNum,
  authContext
) => {
  const {
    sessionData,
    setSessionData,
    navigate,
    handleShowInactiveSessionModal
  } = authContext
  const { authTk, buyerId } = sessionData
  if (!radicatedNum) {
    throw new Error(userquerisBuyerString.radicateNumNull)
  } else if (typeof radicatedNum !== 'string') {
    throw new Error(userquerisBuyerString.radicateNumIsNotString)
  } else if (!buyerId) {
    throw new Error(userquerisBuyerString.buyerIdNull)
  } else if (typeof buyerId !== 'string') {
    throw new Error(userquerisBuyerString.buyerIdNotString)
  }

  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/request/pqr`,
      {
        method: 'POST',
        body: JSON.stringify({
          radicatedNum,
          id: buyerId
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authTk}`,
          TokenCatalogo: token
        }
      }
    )

    const data = await handleSessionFailures(
      response,
      navigate,
      setSessionData,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getBuyerActiveValidation = async (nit, authContext) => {
  const {
    sessionData,
    setSessionData,
    navigate,
    handleShowInactiveSessionModal
  } = authContext
  const { authTk } = sessionData
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/buyer/validateNit/${nit}`, {
        method: 'GET',
        headers: {
          TokenCatalogo: token,
          Authorization: `Bearer ${authTk}`
        }
      }
    )
    const data = await handleSessionFailures(
      response,
      navigate,
      setSessionData,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const validateUserCurrentPurchase = async (buyerId, authContext) => {
  const {
    sessionData,
    setSessionData,
    navigate,
    handleShowInactiveSessionModal
  } = authContext
  const { authTk } = sessionData
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/shoppingHistory/lost-purchase/${buyerId}`, {
        method: 'GET',
        headers: {
          TokenCatalogo: token,
          Authorization: `Bearer ${authTk}`
        }
      }
    )
    const data = await handleSessionFailures(
      response,
      navigate,
      setSessionData,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const createPaymentViaLoopay = async (
  authContext,
  formValues
) => {
  const {
    sessionData,
    setSessionData,
    navigate,
    handleShowInactiveSessionModal
  } = authContext
  const { authTk } = sessionData

  const body = JSON.stringify(formValues)

  let token = await amplify.getToken()
  const isToken = amplify.validateToken(token)
  if (!isToken) {
    amplify.cleanToken()
    await amplify.sign()
    token = await amplify.getToken()
  }
  const requestOptions = {
    method: 'POST',
    body,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authTk}`,
      TokenCatalogo: token
    }
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/payments-receipts/createPaymentBuyer`,
      requestOptions
    )
    if (!response.ok) {
      if (response.status === 500) {
        throw new Error(queriesBuyerString.serverError)
      } else {
        throw new Error(queriesBuyerString.clientError)
      }
    }

    const data = await handleSessionFailures(
      response,
      navigate,
      setSessionData,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getDataBase = async (shoppingHistoryId, authContext) => {
  const {
    sessionData,
    setSessionData,
    navigate,
    handleShowInactiveSessionModal
  } = authContext
  const { authTk } = sessionData
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/shoppingHistory/database/${shoppingHistoryId}`, {
        method: 'GET',
        headers: {
          TokenCatalogo: token,
          Authorization: `Bearer ${authTk}`
        }
      }
    )
    const data = await handleSessionFailures(
      response,
      navigate,
      setSessionData,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}
