export const initialAuthContextState = {
  logged: false,
  authTk: '',
  buyerId: '',
  companyId: '',
  companyComertialName: '',
  buyerAddress: '',
  buyerLegalRepresentative: '',
  buyerContactName: '',
  buyerCredentialEmail: '',
  buyerRepresentativeIdNum: '',
  elements: [],
  currentUsersBuyed: 0,
  pendingProcess: false
}

export const initialDataContextState = {
  companyEmail: '',
  buyerCredentialPass: '',
  activePage: false
}

export const initialDBDetail = {
  shoppingHistoryId: '',
  dbType: '',
  dbUsersQuantity: '',
  dbExpeditionDate: '',
  dbCreateDate: '',
  dbUpdateDate: ' ',
  dbState: null
}

export const joyrideStyles = {
  buttonNext: {
    background: 'none',
    color: '#FF5D00',
    border: 'none',
    fontWeight: '500',
    fontFamily: 'Poppins-Medium',
    fontSize: 18
  },
  buttonSkip: {
    color: '#FF5D00',
    fontWeight: '500',
    fontFamily: 'Poppins-Medium',
    fontSize: 18
  },
  buttonBack: {
    color: '#FF5D00',
    fontWeight: '500',
    fontFamily: 'Poppins-Medium',
    fontSize: 18
  },
  tooltip: {
    textColor: '#000000',
    fontWeight: 'bold',
    width: '23vw',
    borderRadius: 10
  },
  tooltipContainer: {
    fontSize: 20,
    textAlign: 'left',
    fontFamily: 'Poppins-Regular'
  },
  tooltipContent: {
    padding: '20px 10px 0px 10px'
  },
  spotlight: {
    borderRadius: 4
  },
  options: {
    arrowColor: 'white',
    backgroundColor: 'white',
    overlayColor: 'rgba(46,8,31, 0.5)'
  }
}

export const optionsLists = [
  {
    pqrsId: 0,
    pqrsName: 'Getting options...'
  }
]
