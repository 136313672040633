import React, { useContext, useEffect, useState } from 'react'
import { DataContext } from '../../context/DataContext'
import { NavLink, useNavigate } from 'react-router-dom'
import { Modal } from '../../components/Modal/Modal'

// Components
import { GrayCard } from './components/GrayCard/GrayCard'
import { Banner } from './components/Banner/Banner'

// Assets
import phoneIcon from '../../assets/images/Phone.png'
import mailIcon from '../../assets/images/Mail.png'
import bdIcon from '../../assets/icons/icon_bd_gris.svg'
import iconContinuar from '../../assets/icons/icon_continuar.svg'

// Utils
import { accountBuyerString } from '../../utils/strings'

import './Account.css'
import { AuthContext } from '../../context/AuthContext'
import { useModal } from '../../hooks/useModal'
import { getBuyerActiveValidation, validateUserCurrentPurchase } from '../../utils/userqueries'
import { onAcceptPendingProcessClick } from './AccountFunctions'

export const Account = () => {
  const navigate = useNavigate()
  const { contextInfo } = useContext(DataContext)
  const authContext = useContext(AuthContext)
  const { sessionData, setSessionData, handleShowInactiveSessionModal } = authContext
  const {
    companyComertialName,
    buyerLegalRepresentative,
    buyerRepresentativeIdNum,
    buyerCredentialEmail,
    pendingProcess
  } = sessionData
  const [showModal, handleShow, handleClose] = useModal()
  const [showModal2, handleShow2, handleClose2] = useModal()
  const [shoppingHistoryId, setShoppingHistoryId] = useState('')
  const [isRequesting, setIsRequesting] = useState(false)

  useEffect(() => {
    const redirectIfCurrentPurchase = async () => {
      const buyerPat = contextInfo.sessionInfo.findBuyer.buyer.buyerId
      const response = await validateUserCurrentPurchase(buyerPat, authContext)
      const isBody = response?.body.length
      if (isBody && !pendingProcess) {
        handleShow2()
        setSessionData({ ...sessionData, pendingProcess: true })
        setShoppingHistoryId(response.body[0].shoppingHistoryId)
      } else {
        setShoppingHistoryId('')
      }
    }

    redirectIfCurrentPurchase()
  }, [])

  const validateBuyerActive = async () => {
    const buyerPat = contextInfo.sessionInfo.findBuyer.buyer.buyerId
    const responseValidation = await getBuyerActiveValidation(buyerPat, authContext)
    return responseValidation.response
  }

  const handleNavLinkClick = async (e) => {
    e.preventDefault()
    const isBuyerActive = await validateBuyerActive()
    if (isBuyerActive) {
      navigate('../BaseDeDatos/SeleccionPlan')
    } else {
      handleShow()
    }
  }

  return (
    <section className="account">
      <Banner enterpriseName={companyComertialName} />
      <div className="accountNav">
        <p id="Data" className="accountNavItem accountNavItemActive">
          {accountBuyerString.data}
        </p>
      </div>
      <hr className="accountHr" />
      <div className="accountCards">
        <GrayCard title={accountBuyerString.activeDatabases}>
          <img
            className="grayCardImageLeft2 grayCardImageBottom grayCardDataBaseImage"
            src={bdIcon}
            alt={accountBuyerString.iconDatabase}
          />
          {contextInfo.dbpurchased?.length > 0
            ? (
              <p className="grayCardActiveDataBasesRight1">
                {contextInfo.numberOfActiveDataBases}/
                {contextInfo.dbpurchased.length}
              </p>
              )
            : (
              <NavLink
                to="../BaseDeDatos/SeleccionPlan"
                className="grayCardNavLinkRight1"
                onClick={handleNavLinkClick}
              >
                {accountBuyerString.acquireDatabases}
              </NavLink>
              )}
        </GrayCard>
        <GrayCard title={accountBuyerString.tel}>
          <div className="grayCardChildren">
            <p className="grayCardParagraphFont1 grayCardParagraphSize1 grayCardParagraphColor1">
              {contextInfo.sessionInfo?.findBuyer.buyer.companyId.companyPhone}
            </p>
            <img
              className="grayCardImageRight1"
              src={phoneIcon}
              alt={accountBuyerString.iconPhone}
            />
          </div>
        </GrayCard>
        <GrayCard title={accountBuyerString.businessMail}>
          <div className="grayCardChildren">
            <p className="grayCardParagraphFont1 grayCardParagraphColor2 grayCardParagraphSize2 grayCardParagraphMarginTop1">
              {buyerCredentialEmail}
            </p>
            <img
              className="grayCardImageRight1"
              src={mailIcon}
              alt={accountBuyerString.iconEamil}
            />
          </div>
        </GrayCard>
        <GrayCard title={accountBuyerString.legalRepresentative}>
          <p className="grayCardParagraphFont1 grayCardParagraphColor2 grayCardParagraphSize2 grayCardParagraphMarginTop1">
            {buyerLegalRepresentative}
          </p>
          <span className="grayCardParagraphFont1 grayCardParagraphColor2 grayCardParagraphSize2">
            {`${accountBuyerString.cc} ${buyerRepresentativeIdNum}`}
          </span>
        </GrayCard>
      </div>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <img src="" alt="" />
          <p>
            {accountBuyerString.notValidatedTitle}
            <br />
            {accountBuyerString.notValidatedBody}
          </p>
        </Modal.Content>
        <Modal.Buttons>
          <button className='Modal-greenBtn' onClick={handleClose}>OK</button>
        </Modal.Buttons>
      </Modal>
      <Modal isOpen={showModal2}>
        <Modal.Content>
          <img src={iconContinuar} className='continuarIcon' alt="continuarIcon" />
          <p>{accountBuyerString.pendingProcess1} <br />{accountBuyerString.pendingProcess2}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button className='Modal-normalBtn' onClick={handleClose2}>{accountBuyerString.cancel}</button>
          <button
            className='Modal-orangeBtn'
            disabled={isRequesting}
            onClick={() => onAcceptPendingProcessClick(
              navigate,
              shoppingHistoryId,
              sessionData,
              setSessionData,
              handleShowInactiveSessionModal,
              isRequesting,
              setIsRequesting
            )}
          >
            {accountBuyerString.accept}
          </button>
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
